.bs-docs-masthead {
  padding-top: 50px;
  padding-bottom: 40px;
  margin-bottom: 20px;
}

.bs-docs-header h1, .bs-docs-header p {
  margin-right: 0;
}

.bs-docs-sidebar {
    display: none;
    position: -webkit-sticky;
    position: sticky;
    top: 20px;
}

.bs-docs-sidebar .nav > li > a {
  font-size: 15px;
}

.bs-docs-sidebar .nav .nav > li > a {
  font-size: 14px;
}

.back-top-top {
  font-size: 13px;
}

.bs-docs-container {
    position: relative;
}

#jumbotron-header {
    position:relative;

    &:after {
        content: "";
        position: absolute;
        bottom: 0;
        right: 0;
        height: 50px;
        width: 30%;
        background: linear-gradient(135deg, transparent 50px, white 50px);
    }
}
